<template>
  <div>
    <h1 class="header-title mb-3">Transactions</h1>
    <alert
      class="my-4"
      v-if="publicSettings.loading || transactions.isLoading"
    />
    <div v-else>
      <div v-if="publicSettings?.data?.STRIPE_ENABLED">
        <alert
          class="my-4"
          v-if="transactions?.data?.length == 0"
          :hideLoader="true"
          >No Transactions</alert
        >
        <div
          class="
            transaction-body
            card
            border-0
            overflow-auto
            mobile-tablet-no-card
            p-2 p-md-0
          "
          v-if="transactions?.data?.length > 0"
        >
          <table
            class="table table-striped mb-0 responsive-mobile-tablet-table"
          >
            <thead>
              <tr>
                <th>Transaction Date</th>
                <th>Session Date</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Card Ending In</th>
                <th>Receipt</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="transaction in transactions?.data"
                :key="transaction.id"
              >
                <td>
                  <b class="mobile-tablet-only mr-2">Transaction Date</b>
                  <span>{{
                    transaction.created_at && prettyDate(transaction.created_at)
                  }}</span>
                </td>
                <td>
                  <b class="mobile-tablet-only mr-2">Session Date</b>
                  <span>{{
                    transaction.session_date &&
                    getSessionDate(transaction.session_date)
                  }}</span>
                </td>
                <td>
                  <b class="mobile-tablet-only mr-2">Status</b>
                  <span
                    class="text-capitalize"
                    :class="
                      ['succeeded', 'paid'].includes(transaction.status)
                        ? 'text-success'
                        : 'text-danger'
                    "
                  >
                    {{ textToUppercase(transaction.status) }}
                  </span>
                </td>
                <td>
                  <b class="mobile-tablet-only mr-2">Amount</b>
                  <span>{{
                    `$${`${+parseFloat(transaction.amount || 0).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
                  }}</span>
                </td>
                <td>
                  <b class="mobile-tablet-only mr-2">Type</b>
                  <span>{{
                    transaction.card
                      ? transaction.card.brand
                      : "Secure payment link"
                  }}</span>
                </td>
                <td>
                  <b class="mobile-tablet-only mr-2">Card Ending in</b>
                  <span>{{ transaction.card_last4 || "N/A" }}</span>
                </td>
                <td>
                  <div class="mobile-tablet-only mr-2">Receipt</div>
                  <button
                    class="btn p-0 border-0"
                    @click="() => openTransactionModal(transaction)"
                  >
                    <i class="fas fa-print fa-lg"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <TransactionDetailsModal />
      </div>
      <div
        class="
          d-flex
          align-items-center
          justify-content-center
          redirect-to-stripe
        "
        v-else
      >
        <span class="mr-2">Please enable Stripe from the following page</span>
        <router-link
          :to="{ name: 'stripe.config' }"
          class="nav-link p-0"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          >Stripe Configuration</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import helpers from "@/utils/helpers";
import TransactionDetailsModal from "./TransactionDetailsModal.vue";
import dayjs from "dayjs";
export default {
  name: "Transactions",
  components: {
    TransactionDetailsModal,
  },
  computed: {
    ...mapState({
      patients: (state) => state.patients,
      transactions: (state) => state.transactions,
      patient: (state) => state.patients.patient,
      publicSettings: (state) => state.settings.publicSettings,
    }),
  },
  mounted() {
    this.getPublicSettings();
    this.getTransactions(
      this.$route.meta.allUsers ? undefined : this.patient?.user_id
    );
  },
  methods: {
    ...mapActions({
      getTransactions: "transactions/getTransactions",
      selectPrintTransaction: "transactions/selectPrintTransaction",
      getPublicSettings: "settings/getPublicSettings",
    }),
    prettyDate: function (date) {
      return helpers.prettyDate(date, "MMM D YYYY, h:mm A");
    },
    getSessionDate: function (date) {
      const sessionDate = dayjs(date).utc();
      return this.prettyDate(sessionDate);
    },
    textToUppercase: function (text) {
      return helpers.textToUpperCase(text);
    },
    openTransactionModal: function (transaction) {
      this.selectPrintTransaction(transaction);
      this.$modal.show("transaction-modal");
    },
  },
};
</script>
